import React from "react";
import ArgManager from "./ArgManager";
import less from "./OpenApiList.less"

class ArgSetForOpenApi extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            arg: this.props.option.defaultValue || {},
            argUpDate: 0
        }
    }

    componentDidMount() {
        this.props.verification && this.props.verification(this.props.data,this.state.arg);
    }

    render(){
        let {argUpDate,arg} = this.state;
        return <div
            className={less.argSetOuterBox}
        >
            <ArgManager
                key={"arg_" + argUpDate}
                needRequiredField={true}
                needRegexField={true}
                upDateKey={argUpDate}
                argType={"inputArg"}
                couldEdit={true}
                defaultArg={arg}
                defaultSelKey={undefined}
                hideTypeArr={["Array_String","Array_Number"]}
                onSelect={null}
                onSave={(arg, newSelKey) => {
                    this.checkArg(arg, () => {
                        this.saveArg(arg, newSelKey)
                    },(errorMsg)=>{
                        message.error(errorMsg);
                    })
                }}
            />
        </div>
    }

    checkArg(arg, succCb, errorCB){
        let nameEmpty = false;
        let nameSame = false;
        let nameList = [];
        let fieldEmpty = false;
        let fieldSame = false;
        let fieldList = [];
        if (arg && Object.keys(arg).length > 0) {
            Object.keys(arg).forEach((key) => {
                let name = arg[key].name && _.trim(arg[key].name);
                if (!name) {
                    nameEmpty = true;
                } else {
                    if (nameList[name]) {
                        nameSame = true;
                    } else {
                        nameList[name] = true;
                    }
                }
                let field = arg[key].field && _.trim(arg[key].field);
                if (!field) {
                    fieldEmpty = true;
                } else {
                    if (fieldList[field]) {
                        fieldSame = true;
                    } else {
                        fieldList[field] = true;
                    }
                }
            })
        }
        if (fieldEmpty || fieldSame) {
            errorCB && errorCB(fieldEmpty ? "请完善参数key" : (fieldSame ? "存在相同的参数key" : null));
        } else if (nameEmpty || nameSame) {
            errorCB && errorCB(nameEmpty ? "请完善参数名称" : (nameSame ? "存在相同的参数名" : null));
        }else{
            succCb && succCb(arg);
        }
    }

    //保存新的参数
    saveArg(arg, newSelKey) {
        //往外传
        this.props.verification && this.props.verification(this.props.data,arg);
        this.setState({
            arg: arg,
            argUpDate: this.state.argUpDate + 1
        })
    }
}

module.exports = ArgSetForOpenApi;