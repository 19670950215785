import React, {Component} from 'react';
import {Select, Input, Modal, Tooltip, message, InputNumber, Radio, Switch} from 'antd'; // 加载 JS
import styles from "./ArgManager.less";

const DefaultTypeArr = [
  {
    typeKey: "String",
    typeName: "文本",
  },
  {
    typeKey: "Number",
    typeName: "数字",
  },
  {
    typeKey: "Array_String",
    typeName: "文本数组",
  },
  {
    typeKey: "Array_Number",
    typeName: "数字数组",
  },
  {
    typeKey: "Boolean",
    typeName: "真假",
  },
]

class ArgManager extends Component {
  constructor(props) {
    super(props);
    this.argType = this.props.argType || "inputArg";    //inputArg   outputArg
    this.state = {
      arg: _.cloneDeep(this.props.defaultArg) || {},
      cantEditTypeKeys: this.props.defaultArg && Object.keys(this.props.defaultArg) || [],
      selKey: this.props.defaultSelKey,
      editKey: null,
      editTarget: null,
    }

    this.couldSelType = DefaultTypeArr;

    if (this.props.hideTypeArr) {
      this.couldSelType = DefaultTypeArr.filter((item) => {
        return !this.props.hideTypeArr.includes(item.typeKey)
      })
    } else if (this.props.couldSelTypeArr) {
      this.couldSelType = DefaultTypeArr.filter((item) => {
        return this.props.couldSelTypeArr.includes(item.typeKey)
      })
    }

    this.currentSelBox = null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.currentSelBox && this.currentSelBox.scrollIntoView({block: "center", inline: "start"})
    }, 0)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.upDateKey !== this.props.upDateKey) {
      this.setState({
        arg: _.cloneDeep(nextProps.defaultArg),
        selKey: nextProps.defaultSelKey
      })
    }
  }

  render() {
    let {editKey, cantEditTypeKeys, editTarget, tempAddV} = this.state;
    if (editKey) {
      let cantEditType = cantEditTypeKeys.includes(editKey);

      return (<div className={styles.outBox}>
        <div className={styles.headBox}>
          {
            cantEditType
              ? <div>{editTarget.type === "Select" ? "选择型" : "填写型"}</div>
              : [
                <div
                  key={"hns"}
                  className={styles.halfTop + " " + (['Select', 'Multiple'].includes(editTarget.type) ? styles.halfTopUnSel : "")}
                  onClick={() => {
                    editTarget.type = "String";
                    editTarget.list = null;
                    editTarget.value = {
                      defaultValue: undefined
                    };

                    if (this.props.needRequiredField) {
                      //需要设置是否必填字段
                      editTarget.required = true;
                    }
                    if (this.props.needRegexField) {
                      //需要设置校验
                      editTarget.regex = '^[\\S\\s]{1,50}$';
                    }

                    this.setState({
                      editTarget: editTarget
                    })
                  }}
                >填写型</div>,
                <div
                  key={"hs"}
                  className={styles.halfTop + " " + (!['Select', 'Multiple'].includes(editTarget.type) ? styles.halfTopUnSel : "")}
                  onClick={() => {
                    editTarget.type = "Select";
                    editTarget.list = [];
                    editTarget.value = {
                      defaultValue: undefined
                    };

                    if (this.props.needRequiredField) {
                      //需要设置是否必填字段
                      editTarget.required = true;
                    }
                    if (this.props.needRegexField) {
                      //需要设置校验
                      editTarget.regex = null;
                    }

                    this.setState({
                      editTarget: editTarget
                    })
                  }}
                >选择型</div>
              ]
          }
        </div>
        <div className={styles.argBox}>
          <div className={styles.editItem}>
            <div className={styles.editLabel}>
              参数key：
            </div>
            <div className={styles.editValue}>
              <Input
                className={styles.diyInput}
                maxLength={30}
                type="text"
                placeholder={"请填写参数key"}
                value={editTarget.field}
                onChange={(e) => {
                  editTarget.field = e.target.value;
                  this.setState({
                    editTarget: editTarget
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.editItem}>
            <div className={styles.editLabel}>
              名称：
            </div>
            <div className={styles.editValue}>
              <Input
                className={styles.diyInput}
                maxLength={16}
                type="text"
                placeholder={"请填写名称 (最多16个字)"}
                value={editTarget.name}
                onChange={(e) => {
                  editTarget.name = e.target.value;
                  this.setState({
                    editTarget: editTarget
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.editItem}>
            <div className={styles.editLabel}>
              备注：
            </div>
            <div className={styles.editValue}>
              <Input
                className={styles.diyInput}
                maxLength={100}
                type="text"
                placeholder={"请填写备注"}
                value={editTarget.descs}
                onChange={(e) => {
                  editTarget.descs = e.target.value;
                  this.setState({
                    editTarget: editTarget
                  })
                }}
              />
            </div>
          </div>
          {this.props.needRequiredField ? (
            <div className={styles.editItem}>
              <div className={styles.editLabelMust}>必填项：</div>
              <div className={styles.editValue}>
                <Switch
                  checked={editTarget.hasOwnProperty("required") ? editTarget.required : true}
                  checkedChildren={'是'}
                  unCheckedChildren={'否'}
                  onChange={(checked) => {
                    editTarget.required = checked;
                    this.setState({
                      editTarget: editTarget,
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className={styles.editItem}>
            <div className={styles.editLabelMust}>
              值类型：
            </div>
            <div className={styles.editValue}>
              {
                editTarget.type !== "Select"
                  ? <span
                    className={styles.diySelect}
                  >
                                    <Select
                                      disabled={cantEditType}
                                      size={"large"}
                                      style={{width: "100%"}}
                                      dropdownClassName={styles.dropDownClass}
                                      value={editTarget.type}
                                      onChange={(value) => {
                                        editTarget.type = value;

                                        if (['String', 'Array_String', 'By'].includes(value)) {
                                          //文本
                                          editTarget.regex = `^[\\S\\s]{1,50}$`;
                                        } else if (['Url', 'File'].includes(value)) {
                                          //链接
                                          editTarget.regex = `^(https?:\\/\\/).*$`;
                                        } else if (['Number', 'Array_Number'].includes(value)) {
                                          //数字
                                          editTarget.regex = `^-?\\d+(\\.\\d+)?$`;
                                        }

                                        editTarget.value = {
                                          defaultValue: undefined
                                        };
                                        this.setState({
                                          editTarget: editTarget
                                        })
                                      }}
                                    >
                                        {
                                          this.couldSelType.map((item) => {
                                            return <Select.Option
                                              key={item.typeKey}
                                              value={item.typeKey}
                                              style={item.typeKey === editTarget.type ? {backgroundColor: "#4C4CB8"} : null}
                                            >
                                              {item.typeName}
                                            </Select.Option>
                                          })
                                        }
                                    </Select>
                                    </span>
                  : <div className={styles.normal}>文本选择</div>
              }
            </div>
          </div>
          {this.props.needRegexField && !['Select', 'Multiple', 'Boolean'].includes(editTarget.type) ? (
            <div className={styles.editItem}>
              <div className={styles.editLabel}>校验：</div>
              <div className={styles.editValue}>
                <Input
                  className={styles.diyInput}
                  maxLength={250}
                  type="text"
                  placeholder={'请填写正则表达式内容，不需要填写前后斜杠'}
                  value={editTarget.regex}
                  onChange={(e) => {
                    editTarget.regex = e.target.value;
                    this.setState({
                      editTarget: editTarget,
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
          {
            editTarget.type === "Select"
              ? <div className={styles.editItem}>
                <div className={styles.editLabel}>
                  可选项：
                </div>
                <div className={styles.editValue}>
                                    <span className={styles.diySelect}>
                                        <Select
                                          placeholder={(editTarget.list && editTarget.list.length) ? `已添加 ${editTarget.list.length} 项` : "未添加"}
                                          size={"large"}
                                          style={{width: "100%"}}
                                          value={null}
                                        >
                                        {
                                          editTarget.list && editTarget.list.map((str) => {
                                            return <Select.Option
                                              key={str}
                                              value={str}
                                            >
                                              <div className={styles.addSelItem}>
                                                <div className={styles.al}>
                                                  <Tooltip title={str}>
                                                    {str}
                                                  </Tooltip>
                                                </div>
                                                <div
                                                  className={styles.deleteSelIcon}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    editTarget.list = editTarget.list.filter((v) => {
                                                      return v !== str
                                                    });
                                                    if (editTarget.value && editTarget.value.defaultValue === str) {
                                                      editTarget.value.defaultValue = undefined
                                                    }
                                                    this.setState({
                                                      editTarget: editTarget,
                                                    })
                                                  }}
                                                ></div>
                                              </div>
                                            </Select.Option>
                                          })
                                        }
                                    </Select>
                                    </span>
                </div>
              </div>
              : null
          }
          {
            editTarget.type === "Select"
              ? <div className={styles.editItem}>
                <div className={styles.editLabel}>
                  增项：
                </div>
                <div className={styles.editValue}>
                  <div className={styles.addInput}>
                    <Input
                      style={{width: "90%"}}
                      value={tempAddV}
                      placeholder={"请输入新选项"}
                      className={styles.diyInput02}
                      onPressEnter={(e) => {
                        e.stopPropagation();
                        this.addSelectItem()
                      }}
                      onChange={(e) => {
                        this.setState({
                          tempAddV: e.target.value
                        })
                      }}
                    />
                    <div
                      className={tempAddV ? styles.addIcon : styles.addIconDis}
                      onClick={() => {
                        this.addSelectItem()
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              : null
          }
          <div className={styles.editItem}>
            <div className={styles.editLabel}>
              默认值：
            </div>
            <div className={styles.editValue}>
              {this.getDefaultValueSet(editTarget)}
            </div>
          </div>
        </div>
        <div className={styles.bottomBox}>
          <div className={styles.twoBtns}>
            <div
              className={styles.halfBtn}
              style={{color: "red"}}
              onClick={() => {
                this.setState({
                  editKey: null,
                  editTarget: null,
                })
              }}
            >
              <span className={styles.closeBtnIcon}></span>
              取消
            </div>
            <div className={styles.line}></div>
            <div
              className={styles.halfBtn}
              onClick={() => {
                this.saveEdit()
              }}
            >
              <span className={styles.saveBtnIcon}></span>
              保存
            </div>
          </div>
        </div>
      </div>)
    } else {
      return (<div className={styles.outBox}>
        <div className={styles.headBox}>
          {{
            "inputArg": "输入参数配置",
            "outputArg": "输出参数配置"
          }[this.argType]}
        </div>
        <div className={styles.argBox}>
          {this.getArg(this.state.arg)}
        </div>
        <div className={styles.bottomBox}>
          {
            this.props.onSelect
              ? <div className={styles.twoBtns}>
                {
                  this.props.couldEdit
                    ? [
                      <div
                        key={"addBtn"}
                        className={styles.halfBtn}
                        onClick={() => {
                          let uid = window.getUID();
                          let newTarget = {
                            key: uid,
                            type: 'String',
                            name: undefined,
                            descs: undefined,
                            order: this.getPlusOrder(this.state.arg),
                            value: {
                              defaultValue: undefined,
                            },
                          };

                          if (this.props.needRequiredField) {
                            //需要设置是否必填字段
                            newTarget.required = true;
                          }
                          if (this.props.needRegexField) {
                            //需要设置正则
                            newTarget.regex = '^[\\S\\s]{1,50}$';
                          }

                          this.setState({
                            editKey: uid,
                            editTarget: newTarget,
                          });
                        }}
                      >
                        <span className={styles.plusBtnIcon}></span>
                        新增
                      </div>,
                      <div key={"line"} className={styles.line}></div>
                    ]
                    : null
                }
                <div
                  className={styles.halfBtn}
                  onClick={() => {
                    if (!this.state.selKey) {
                      message.warning("请选择参数");
                      return;
                    }
                    if (!this.state.arg[this.state.selKey]) {
                      message.warning("该参数已被删除，请重新选择");
                      return;
                    }
                    this.props.onSelect && this.props.onSelect(this.state.selKey, this.state.arg);
                  }}
                >
                  <span className={styles.confirmBtnIcon}></span>
                  确认
                </div>
              </div>
              : (
                this.props.couldEdit
                  ? <div
                    className={styles.addBtnAlone}
                    onClick={() => {
                      let uid = window.getUID();
                      let newTarget = {
                        key: uid,
                        type: 'String',
                        name: undefined,
                        descs: undefined,
                        order: this.getPlusOrder(this.state.arg),
                        value: {
                          defaultValue: undefined,
                        },
                      };

                      if (this.props.needRequiredField) {
                        //需要设置是否必填字段
                        newTarget.required = true;
                      }

                      if (this.props.needRegexField) {
                        //需要设置正则
                        newTarget.regex = '^[\\S\\s]{1,50}$';
                      }

                      this.setState({
                        editKey: uid,
                        editTarget: newTarget,
                      });
                    }}
                  >
                    <div>
                      <span className={styles.plusBtnIcon}></span>
                      新增
                    </div>
                  </div>
                  : null
              )
          }
        </div>
      </div>)
    }
  }

  //最大order+1
  getPlusOrder(arg) {
    let max = 0;
    Object.values(arg).forEach((item) => {
      max = Math.max(item.order || 0, max);
    });

    return max + 1;
  }

  addSelectItem() {
    let {editTarget, tempAddV} = this.state;
    let v = _.trim(tempAddV);
    if (!v) {
      return;
    }
    let list = editTarget.list || [];
    if (list.includes(_.trim(v))) {
      message.warning("该选项已经存在");
      return;
    }

    list.push(v);
    editTarget.list = list;
    this.setState({
      editTarget: editTarget,
      tempAddV: undefined
    })
  }

  getDefaultValueSet(editTarget) {
    let view;
    switch (editTarget.type) {
      case "Select":
        view = <span className={styles.diySelect}>
                    <Select
                      size={"large"}
                      placeholder={(editTarget.list && editTarget.list.length) ? "请选择默认值" : "无可选项"}
                      style={{width: "100%"}}
                      allowClear={true}
                      dropdownClassName={styles.dropDownClass}
                      value={editTarget.value && editTarget.value.defaultValue}
                      onChange={(value) => {
                        editTarget.value = {
                          defaultValue: value
                        }
                        this.setState({
                          editTarget: editTarget
                        })
                      }}
                    >
                    {
                      editTarget.list && editTarget.list.map((str) => {
                        return <Select.Option
                          key={str}
                          value={str}
                          style={str === (editTarget.value && editTarget.value.defaultValue) ? {backgroundColor: "#4C4CB8"} : null}
                        >
                          {str}
                        </Select.Option>
                      })
                    }
                </Select>
                </span>;
        break;
      case "String":
      case "By":
        view = <Input
          placeholder={"请填写默认值"}
          className={styles.diyInput}
          value={editTarget.value && editTarget.value.defaultValue}
          onChange={(e) => {
            editTarget.value = {
              defaultValue: e.target.value
            }
            this.setState({
              editTarget: editTarget
            })
          }}
        />;
        break;
      case "Number":
        view = <div className={styles.diyInputNumber}>
          <InputNumber
            placeholder={"请填写默认值"}
            style={{width: "100%"}}
            value={editTarget.value && editTarget.value.defaultValue}
            onChange={(value) => {
              editTarget.value = {
                defaultValue: value
              }
              this.setState({
                editTarget: editTarget
              })
            }}
          />
        </div>;
        break;
      case "Boolean":
        view = <Radio.Group
          className={styles.diyRadio}
          value={editTarget.value && editTarget.value.defaultValue}
        >
          <Radio
            value={true}
            onClick={() => {
              editTarget.value = {
                defaultValue: (editTarget.value && editTarget.value.defaultValue === true) ? null : true
              }
              this.setState({
                editTarget: editTarget
              })
            }}
          >真</Radio>
          <Radio
            value={false}
            onClick={() => {
              editTarget.value = {
                defaultValue: (editTarget.value && editTarget.value.defaultValue === false) ? null : false
              }
              this.setState({
                editTarget: editTarget
              })
            }}
          >假</Radio>
        </Radio.Group>;
        break;
      case "Array_String":
        view = <span className={styles.diySelect}>
                    <Select
                      size={"large"}
                      placeholder={"请填写默认值"}
                      style={{width: "100%"}}
                      dropdownClassName={styles.dropDownClass}
                      value={editTarget.value && editTarget.value.defaultValue}
                      allowClear={true}
                      mode={"tags"}
                      dropdownRender={() => {
                        return null
                      }}
                      onChange={(value) => {
                        editTarget.value = {
                          defaultValue: value
                        }
                        this.setState({
                          editTarget: editTarget
                        })
                      }}
                    >

                </Select>
                </span>;
        break;
      case "Array_Number":
        view = <span className={styles.diySelect}>
                    <Select
                      size={"large"}
                      placeholder={"请填写默认值"}
                      style={{width: "100%"}}
                      dropdownClassName={styles.dropDownClass}
                      value={editTarget.value && editTarget.value.defaultValue}
                      allowClear={true}
                      mode={"tags"}
                      dropdownRender={() => {
                        return null
                      }}
                      onChange={(value) => {
                        let result = [];
                        value.forEach((str) => {
                          if (str === "0") {
                            return result.push(0);
                          }
                          if (Number.parseFloat(str)) {
                            return result.push(Number.parseFloat(str));
                          }
                        })
                        editTarget.value = {
                          defaultValue: result
                        }
                        this.setState({
                          editTarget: editTarget
                        })
                      }}
                    >

                </Select>
                </span>;
        break;
    }

    return view;
  }

  saveEdit() {
    let {arg, editKey, editTarget} = this.state;

    editTarget.name = editTarget.name ? _.trim(editTarget.name) : null;
    if (!editTarget.name) {
      message.warning("请填写名称");
      return;
    }
    if (!editTarget.type) {
      message.warning("请选择值类型");
      return;
    }
    if (this.props.needRegexField) {
      // 校验规则改成非必填，这层拦截去掉
      // if(!["Select","Multiple", "Boolean"].includes(editTarget.type) && !editTarget.regex){
      //     message.warning("请填写校验正则");
      //     return;
      // }

      if (['Select', 'Multiple', 'Boolean'].includes(editTarget.type)) {
        //这几种类型不需要填写校验规则，即使有默认值，也删掉
        editTarget.regex = null;
      }

      if (editTarget.regex) {
        if (!this.isValidRegex(editTarget.regex)) {
          message.warning('请填写格式正确的正则表达式');
          return;
        }

        // "String": "文本",
        //   "Number": "数字",
        //   "Array_String": "文本数组",
        //   "Array_Number": "数字数组",
        //   "By": "节点条件",
        //   "Boolean": "真假",
        //   "Url": "网址链接",
        //   "File": "媒体或文件",
        if (['String', 'Number', 'By', 'Url', 'File'].includes(editTarget.type) && editTarget.value?.defaultValue) {
          let reg = new RegExp(editTarget.regex);
          if (!reg.test(editTarget.value.defaultValue)) {
            message.warning('填写的默认值不符合配置的校验规则');
            return;
          }
        }
        if (['Array_String', 'Array_Number'].includes(editTarget.type) && editTarget.value?.defaultValue?.length) {
          let reg = new RegExp(editTarget.regex);
          let isValid = editTarget.value?.defaultValue.every((val) => reg.test(val));
          if (!isValid) {
            message.warning('填写的默认值不符合配置的校验规则');
            return;
          }
        }
      }
    }

    if (editTarget.type === "Select" && (!editTarget.list || !editTarget.list.length)) {
      message.warning("请补充可选项");
      return;
    }

    let nameSame = false;
    Object.keys(arg).forEach((key) => {
      if (arg[key].name === editTarget.name && key !== editKey) {
        nameSame = true;
      }
    })

    if (nameSame) {
      message.warning("该参数名已被使用，请修改");
      return;
    }

    arg[editKey] = editTarget;
    this.setState({
      editKey: null,
      editTarget: null,
      arg: arg,
      selKey: editKey
    }, () => {
      this.props.onSave && this.props.onSave(this.state.arg, editKey)
    })
  }

  //只判断正则的中间内容
  isValidRegex(pattern) {
    try {
      new RegExp(pattern);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   *    //渲染已经存在的参数
   * @param arg
   * @returns {JSX.Element|unknown[]}
   */
  getArg(arg) {
    if (!arg || Object.keys(arg).length === 0) {
      return <div className={styles.emptyBox}>未定义任何参数</div>
    }

    return Object.keys(arg).map((key) => {
      let item = arg[key];
      return (<div
        key={key}
        className={styles.box}
      >
        <div
          ref={(ref) => {
            if (key === this.state.selKey) {
              this.currentSelBox = ref
            }
          }}
          className={styles.left}
          style={this.props.onSelect ? {cursor: "pointer"} : null}
          onClick={() => {
            if (!this.props.onSelect) {
              return;
            }
            this.setState({
              selKey: key === this.state.selKey ? null : key
            })
          }}
        >
          <div
            className={styles.item}
          >
            <div className={styles.label}>参数key：</div>
            <div className={styles.value}>
              <Tooltip title={item.field}>
                {item.field}
              </Tooltip>
            </div>
          </div>
          <div
            className={styles.item}
          >
            {this.props.onSelect
              ? <div
                className={key === this.state.selKey ? styles.selectedIcon : styles.unSelIcon}
              >
              </div>
              : null}
            <div className={styles.label}>名称：</div>
            <div className={styles.value}>
              <Tooltip title={item.name}>
                {item.name}
              </Tooltip>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>值类型：</div>
            <div className={styles.value}>
              {
                {
                  "String": "文本",
                  "Number": "数字",
                  "Array_String": "文本数组",
                  "Array_Number": "数字数组",
                  "By": "节点条件",
                  "Boolean": "真假",
                  "Select": "文本选择",
                }[item.type]
              }
            </div>
            <div
              className={styles.label}
              style={{marginLeft: "16px"}}
            >默认值：
            </div>
            <div
              className={styles.value}
            >
              {this.getDefaultValueShow(item)}
            </div>
          </div>
          {this.props.needRequiredField ? (
            <div className={styles.item}>
              <div className={styles.label}>必填项：</div>
              <div className={styles.value}>
                {
                  item?.hasOwnProperty("required")
                    ?(item.required ? '是' : '否')
                    :'是'
                }
              </div>
            </div>
          ) : null}
          {this.props.needRegexField && !['Select', 'Multiple', 'Boolean'].includes(item.type) ? (
            <div className={styles.item}>
              <div className={styles.label}>校验：</div>
              <div className={styles.value}>{item.regex}</div>
            </div>
          ) : null}
          <div className={styles.item02}>
            <div className={styles.label}>备注：</div>
            <div className={styles.value}>
              {item.descs}
            </div>
          </div>
        </div>
        {
          this.props.couldEdit
            ? <div className={styles.right}>
              <div
                className={styles.editIcon}
                onClick={() => {
                  this.setState({
                    editKey: key,
                    editTarget: _.cloneDeep(item),
                  })
                }}
              ></div>
              <div
                className={styles.deleteIcon}
                onClick={() => {
                  Modal.confirm({
                    zIndex: 1003,
                    title: "提示",
                    content: '是否删除参数 ' + item.name + " ？",
                    okText: '删除',
                    okType: 'danger',
                    okButtonProps: {style: {background: "#e4693c", color: "#fff"}},
                    cancelText: '取消',
                    onOk: () => {
                      let arg = this.state.arg;
                      delete arg[key];
                      this.setState(arg, () => {
                        this.props.onSave && this.props.onSave(this.state.arg)
                      })
                    },
                  })
                }}
              ></div>
            </div>
            : null
        }
      </div>)
    })
  }

  getDefaultValueShow(item) {
    if (!item.value || item.value.defaultValue === undefined || item.value.defaultValue === null) {
      return <span style={{color: "gray"}}>未设置</span>
    }

    if (item.value.defaultValue === true) {
      return "真"
    }
    if (item.value.defaultValue === false) {
      return "假"
    }

    return <Tooltip title={item.value.defaultValue.toString()}>
      {item.value.defaultValue.toString()}
    </Tooltip>
  }

  /**
   * 修改state中深层级对象
   * @param changeConfig objArray [{matchString,value}]
   * @param cb
   */
  changeStateValue(changeConfigArr, cb) {
    if (!changeConfigArr) {
      console.error("没有传入修改配置");
      return;
    }

    let configArr = [];
    if (Array.isArray(changeConfigArr)) {
      configArr = changeConfigArr;
    } else {
      configArr = [changeConfigArr];
    }

    let state = this.state;
    configArr.forEach((configItem, index) => {
      if (configItem["matchString"]) {
        //存在路径配置，至少能走一级
        let step = configItem["matchString"].split(".");
        let deep = state;
        for (let i = 0, l = step.length; i < l; i++) {
          // console.log(step[i]);
          if (i < l - 1) {
            if (!deep[step[i]]) {
              //没有这一层，且不是最后一个循环，创建一个
              deep[step[i]] = {};
            }
            deep = deep[step[i]];
          } else {
            deep[step[i]] = configItem["value"];
          }
        }

        console.log(state);
      }
    });
    this.setState(state, () => {
      //内部模块的回调
      cb && cb(state);
    });
  }
}

ArgManager.defaultProps = {
  couldAdd: false,
};

export default ArgManager;