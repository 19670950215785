import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Modal, message, Button} from 'antd';
import styles from "./DeviceList.less";
import LayoutAreaSelect from "../../../components/areaSelect/AreaSelectUseForLayout";
import HttpTool from "../../../tool/HttpTool";
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index";
import CodeAndRomSel from "./CodeAndRomSel";

const MB = new ModalBase();

class DeviceList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getDeviceList,
                otherParam: {companyIds: this.props.post.spaceId ? [this.props.post.spaceId] : null},
            },

            // new: {
            //     defaultValue: '添加设备',
            //     action: () => {
            //         this.props.openTab({
            //             path: 'DeviceAdd',
            //             title: '批量添加设备',
            //             post: {
            //                 parent: this.props
            //             }
            //         })
            //     }
            // },
            toolBar: () => {
                return this.powerConfig.Edit
                    ?<div className={styles.toolBar}>
                        <Button
                            type={"primary"}
                            className={styles.toolBtn}
                            onClick={() => {
                                this.props.openTab({
                                    path: 'DeviceShare',
                                    title: '批量分配设备',
                                    post: {
                                        parent: this.props
                                    }
                                })
                            }}>批量分配设备</Button>

                        <Button
                            type={"primary"}
                            className={styles.toolBtn}
                            onClick={() => {
                                this.httpGetDevices((data) => {

                                    this.props.openTab({
                                        path: 'DeviceShare',
                                        title: '批量分配设备',
                                        post: {
                                            parent: this.props,
                                            deviceList: data
                                        }
                                    })
                                });
                            }}
                        >批量分配以下设备</Button>

                        <Button
                            className={styles.toolBtn}
                            onClick={() => {
                                this.props.openTab({
                                    path: 'DeviceUnbind',
                                    title: '批量解绑设备',
                                    post: {
                                        parent: this.props
                                    }
                                })
                            }}>批量解绑设备</Button>

                        <Button
                            className={styles.toolBtn}
                            onClick={() => {
                                this.httpGetDevices((data) => {
                                    this.props.openTab({
                                        path: 'DeviceUnbind',
                                        title: '批量解绑设备',
                                        post: {
                                            parent: this.props,
                                            deviceList: data
                                        }
                                    })
                                });
                            }}
                        >批量解绑以下设备</Button>
                    </div>
                    :null
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '请输入设备号搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        if (value.companyIds && typeof value.companyIds === "string") {
                            if (value.companyIds === '&*^unshare') {
                                value.companyIds = [];
                            } else {
                                value.companyIds = [value.companyIds];
                            }
                        } else {
                            if (value.companyIds) {
                                value.companyIds = [value.companyIds.key]
                            }
                        }
                        if (value.statuss && typeof value.statuss !== "array") {
                            value.statuss = [value.statuss];
                        }
                        if (value.types && typeof value.types !== "array") {
                            value.types = [value.types];
                        }
                        if (value.chooseAddTime) {
                            value.beginAddtime = value.chooseAddTime[0];
                            value.endAddtime = value.chooseAddTime[1];
                        }
                        if (value.chooseEndTime) {
                            value.beginLasttime = value.chooseEndTime[0];
                            value.endLasttime = value.chooseEndTime[1];
                        }
                        if (value.keyword) {
                            value.deviceId = value.keyword;
                        }
                        if (value.areaData) {
                            if (value.areaData.countryId) {
                                value.countryIds = [value.areaData.countryId]
                            }
                            if (value.areaData.provinceId) {
                                value.provinceIds = [value.areaData.provinceId]
                            }
                            if (value.areaData.cityId) {
                                value.cityIds = [value.areaData.cityId]
                            }
                        }

                        if(value.codeAndRom?.code){
                            value.codes = [value.codeAndRom?.code]
                        }
                        if(value.codeAndRom?.romIds){
                            value.romIds = value.codeAndRom?.romIds
                        }


                        this.saveValue = value;

                        return true;
                    },
                }
            },
        }
    }

    getSeniorSearch() {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "statuss",
                    name: "状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部状态',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部状态',
                            value: '',
                        },
                        {
                            title: '未激活',
                            value: -1,
                        },
                        {
                            title: '空闲',
                            value: 2,
                        },
                        {
                            title: '离线',
                            value: 1,
                        },
                        {
                            title: '忙碌',
                            value: 3,
                        },
                    ]
                },
                {
                    ...defaultOption,
                    field: "types",
                    name: "机型",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部机型',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部机型',
                            value: '',
                        },
                        {
                            title: '电视盒子',
                            value: 1,
                        },
                        {
                            title: '手机',
                            value: 2,
                        },
                        {
                            title: 'PC',
                            value: 3,
                        },
                        {
                            title: '服务器',
                            value: 4,
                        },
                    ]
                },
                // {
                //     ...defaultOption,
                //     field: "romIds",
                //     name: "系统",
                //     type: 'selectAll',
                //     selectType: 'value',
                //     reg: (v) => {
                //         return true;
                //     },
                //
                //     apiConfig: {
                //         url: APILXD.getDeviceRomIdList,
                //         fillObject: (json) => {
                //             let data = [];
                //             for (let obj of json) {
                //                 data.push({
                //                     title: obj,
                //                     value: obj,
                //                 });
                //             }
                //             return data;
                //         }
                //     },
                //     option: {
                //         allowClear: true,
                //         dropdownMatchSelectWidth: false,
                //         showSearch: true,
                //         onSearch: ()=>{},
                //         filterOption: (input, option) => {
                //             return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                //         },
                //         optionFilterProp: "children",
                //     },
                //     data: []
                // },
                {
                    ...defaultOption,
                    field: "codeAndRom",
                    name: "终端",
                    type: 'diy',
                    component: CodeAndRomSel,
                    reg: (v) => {
                        return true;
                    },
                    option: {

                    },
                    data: []
                },
                {
                    ...defaultOption,
                    field: "center",
                    name: "center版本",
                    type: 'input',
                    reg: (v) => {
                        return true;
                    },
                },
                {
                    ...defaultOption,
                    field: "remote",
                    name: "remote版本",
                    type: 'input',
                    reg: (v) => {
                        return true;
                    },
                },
                {
                    ...defaultOption,
                    field: "script",
                    name: "script版本",
                    type: 'input',
                    reg: (v) => {
                        return true;
                    },
                },
                {
                    field: "companyIds",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.getAllSpaces,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            data.unshift({
                                title: '未分配公司',
                                value: "&*^unshare",
                            });
                            data.unshift({
                                title: '全部公司',
                                value: '',
                            });

                            return data;
                        }
                    },
                    option: {
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: () => {},
                        placeholder: "输入名称可以精确匹配",
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                        defaultValue: this.props.post.spaceId ? {
                            key: this.props.post.spaceId + ""
                        } : {
                            key: '',
                            value: '',
                            title: '全部公司'
                        },
                    },
                    data: []
                },
                {
                    ...defaultOption,
                    field: "chooseAddTime",
                    name: "设备添加时间",
                    reg: (txt) => {
                        return !!txt;
                    },
                    type: 'rangePicker',
                    verMessage: "请选择",
                    option: {
                        showTime: true,
                        style: {width: '100%'},
                        format: "YYYY-MM-DD hh:mm:ss",
                        placeholder: ['起始时间', '结束时间'],
                    }
                },
                {
                    ...defaultOption,
                    field: "chooseEndTime",
                    name: "设备最后请求时间",
                    reg: (txt) => {
                        return !!txt;
                    },
                    type: 'rangePicker',
                    verMessage: "请选择",
                    option: {
                        style: {width: '100%'},
                        showTime: true,
                        format: "YYYY-MM-DD hh:mm:ss",
                        placeholder: ['起始时间', '结束时间'],
                    }
                },
                {
                    ...defaultOption,
                    field: "areaData",
                    name: "地区过滤",
                    reg: (v) => {
                        console.log("v")
                        console.log(v)
                        return !!v;
                    },
                    type: 'diy',
                    component: LayoutAreaSelect,
                    verMessage: "请选择",
                    option: {}
                }
            ],
        }
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '设备号',
                dataIndex: '_id',
                key: '_id',
            },
            {
                title: '机型',
                dataIndex: 'type',
                key: 'type',
                render: (text, record, index) => {
                    let name = "未知";
                    switch (text) {
                        case 1:
                            name = "电视盒子";
                            break;
                        case 2:
                            name = "手机";
                            break;
                        case 3:
                            name = "PC";
                            break;
                        case 4:
                            name = "服务器";
                            break;
                        default:
                            name = "未知";
                    }
                    return <div>
                        {name}
                    </div>
                }
            },
            {
                title: '终端分类/系统',
                dataIndex: 'romId',
                key: 'romId',
                render: (text,record)=>{
                    return <div>
                        <div>{record.classificationName || "未分类"}</div>
                        <div style={{color: "#999"}}>{text}</div>
                    </div>
                }
            },
            {
                title: '应用版本',
                dataIndex: 'center',
                key: 'center',
                render: (text, record)=>{
                    return <div>
                        <div>center: {record.center}</div>
                        <div>remote: {record.remote}</div>
                        <div>script: {record.script}</div>
                        <div>client: {record.clientVersion}</div>
                    </div>
                }
            },
            {
                title: '设备添加时间',
                dataIndex: 'addTime',
                key: 'addTime',
                render: (text) => {
                    let time = "";
                    if (text) {
                        time = TimeHelp.getYMDHMS(text);
                    }
                    return <div>{time}</div>
                }
            },
            {
                title: '最后请求时间',
                dataIndex: 'lasttime',
                key: 'lasttime',
                render: (text) => {
                    let time = "";
                    if (text) {
                        time = TimeHelp.getYMDHMS(text);
                    }
                    return <div>{time}</div>
                }
            },
            // {
            //     title: '安卓版本',
            //     dataIndex: 'SDK',
            //     key: 'SDK',
            // },
            {
                title: '最后请求地点',
                dataIndex: 'location',
                key: 'location',
                render: (text, record, index) => {
                    let countryName = text && text.countryName ? (text.countryName + " ") : "";
                    let provinceName = text && text.provinceName ? (text.provinceName + " ") : "";
                    let cityName = text && text.cityName || "";
                    return <div>{
                        countryName + provinceName + cityName
                    }
                    </div>
                }
            },
            {
                title: '最后请求ip',
                dataIndex: 'ip',
                key: 'ip',
                render: (text, record) => {
                    return <div>
                        <div>公网:{text || "-"}</div>
                        <div>内网:{record.lanIp || "-"}</div>
                    </div>
                }
            },
            {
                title: '所属公司',
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    let map = {
                        "1": {text: "离线", color: "red"},
                        "2": {text: "空闲", color: "green"},
                        "3": {text: "忙碌", color: "blue"},
                        "-1": {text: "未激活", color: "gray"},
                        "null": {text: "未激活", color: "gray"},

                    };
                    let statusMap = map[text + ""];
                    if (!statusMap) {
                        statusMap = {text: "未知", color: "red"}
                    }
                    return <span style={{color: statusMap.color}}>{statusMap.text}</span>
                }
            },
            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return (
                        this.powerConfig.Edit
                            ?<div>
                                {
                                    record.spaceId ? <span onClick={() => {
                                            this.unbindDevice(record._id);
                                        }} className={styles.clickable} style={{marginLeft: 10,}}>解绑</span>
                                        :
                                        <span onClick={() => {
                                            this.bindDevice(record._id);
                                        }} className={styles.clickable} style={{marginLeft: 10, color: "green"}}>绑定</span>
                                }

                            </div>
                            :null
                        )
                }
            },
        ]
    }

    httpGetDevices(callback) {
        this._showLoading(true);
        if (!this.saveValue) {
            this.saveValue = {
                chooseAddTime: null,
                chooseEndTime: null,
                keyword: null,
                page: 1,
                pageSize: 10,
                romIds: null,
                companyIds: null,
                statuss: null,
                types: null,
            }
        }

        let param = this.saveValue;
        let successCB = (code, msg, json, option) => {
            this._showLoading(false);
            callback && callback(json);
        };
        let failureCB = (code, msg) => {
            message.error(msg);
            this._showLoading(false);
        };

        HttpTool.post(APILXD.getDeviceIds, successCB, failureCB, param);
    }

    unbindDevice(deviceId) {
        Modal.confirm({
            title: '提示',
            content: '确定解绑该设备吗',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this._showLoading(true);
                let param = {
                    deviceIds: [deviceId]
                };
                let successCB = (code, msg, json, option) => {
                    message.success('解绑成功');
                    this._loadDataForNet();
                    this._showLoading(false);
                };
                let failureCB = (code, msg) => {
                    message.error(msg);
                    this._showLoading(false);
                };

                HttpTool.post(APILXD.assignDeviceListToSpace, successCB, failureCB, param);
            }
        });
    }

    bindDevice(id) {
        let addData = this.getAddData(id);
        MB.show(
            {
                title: "绑定公司",
                okTitle: "绑定",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.assignDeviceListToSpace,
                beforeSubmit: (param) => {
                    if (param.deviceIds) {
                        param.deviceIds = [param.deviceIds];
                    }
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    getAddData(id) {
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 12, offset: 0},
            },
            parameterArr: [
                {
                    field: "deviceIds",
                    name: '设备号',
                    option: {
                        placeholder: '请输入账号',
                        defaultValue: id,
                    }
                },
                {
                    field: "companyId",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.getAllSpaces,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            return data;
                        }
                    }
                }
            ],
        };
        return {
            props,
            otherParam: null
        }
    }
}

module.exports = DeviceList;